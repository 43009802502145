import React, { useEffect, useState } from "react";
import { FileField, useRecordContext } from "react-admin";
import LinearProgress from "@mui/material/LinearProgress";

const svgLengthVal = (sl) =>
  sl.unitType === 5 || sl.unitType === 1 ? sl.value : "Invalid unit type";

const fetchSvgInfo = (url) => {
  return fetch(url)
    .then((response) => response.text())
    .then((data) => {
      const parser = new DOMParser();
      const svg = parser.parseFromString(data, "image/svg+xml");
      const wrapper = document.createElement("div");
      wrapper.innerHTML = svg.documentElement.outerHTML;

      return {
        width: svgLengthVal(wrapper.firstChild.width.baseVal),
        height: svgLengthVal(wrapper.firstChild.height.baseVal),
      };
    });
};

export const SvgFileInfoField = (props) => {
  const { url } = useRecordContext();
  const [{ loading, error, width, height }, setState] = useState({
    loading: true,
    error: null,
    width: null,
    height: null,
  });

  useEffect(() => {
    fetchSvgInfo(url)
      .then(({ width, height }) =>
        setState({
          loading: false,
          error: null,
          width,
          height,
        })
      )
      .catch((error) => setState({ error, loading: false }));
  }, [url]);

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    return <div>Error requesting model data: {error.message}</div>;
  }

  return (
    <>
      <FileField {...props} />
      <div>
        Размеры: {width} x {height}
      </div>
    </>
  );
};
