import React from 'react';
import { ThemeProvider } from '@mui/styles';
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'

const LoginPage = props => {
    const { theme, authUrl } = props;

    return (
        <ThemeProvider theme={theme}>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ minHeight: '100vh' }}
            >
                <Grid item xs={3}>
                    <Button variant="contained" color="primary" onClick={() => window.location.href = authUrl()}>Войти</Button>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
};

export default (LoginPage)
