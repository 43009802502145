import React from "react";
import {
  List,
  Datagrid,
  BooleanField,
  ReferenceField,
  FunctionField,
  SelectField,
  ImageField,
  FileField,
  EditButton,
  ShowButton,
  Edit,
  Create,
  SimpleForm,
  TextInput,
  TextField,
  ReferenceInput,
  NumberInput,
  SelectInput,
  required,
  choices,
  number,
  Filter,
  BooleanInput,
  NullableBooleanInput,
  regex,
  useRecordContext,
  Labeled,
} from "react-admin";
import { ShortUrlField, ModelDataField, SvgFileInfoField } from "../field/";
import { AjaxFileInput, TagsAutocompleteInput } from "../input/";

import { renderNameWithLevel as itemGroupRenderName } from "../itemsGroups";
import FileUploader from "../FileUploader.js";
import { get } from "lodash";
import Launch from "@mui/icons-material/Launch";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import RenderButton from "../button/RenderButton";
import { CustomToolbar } from "./form_toolbar";
import JsonInput from "../components/JsonInput.js";
import { PLAYER_URL_TEMPLATE } from "../config.js";

const ItemFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Поиск" source="_q" alwaysOn />
    <NullableBooleanInput label="Активен" source="_enabled" alwaysOn />
    <NullableBooleanInput
      label="Настроен"
      source="_configurationDone"
      alwaysOn
    />
    <SelectInput
      source="_status"
      choices={STATUSES}
      optionText="name"
      label="Статус"
      alwaysOn
    />
  </Filter>
);

const renderWHD = (record) => {
  const { width, height, depth } = record;
  return `${width ? parseFloat(width) : 0} x ${
    height ? parseFloat(height) : 0
  } x ${depth ? parseFloat(depth) : 0}`;
};

const RenderRenderUrl = (record) => {
  const renderId =
    get(record, "usedRenderTask.id") || get(record, "lastRenderTask.id");
  return renderId ? (
    <a href={PLAYER_URL_TEMPLATE.replace("%id", renderId)}>
      <Launch />
    </a>
  ) : null;
};

const TYPES = [
  { id: "window", name: "Окно" },
  { id: "door", name: "Дверь" },
  { id: "light", name: "Освещение" },
  { id: "size_plate", name: "Обозначение - длина стены" },
  { id: "area_plate", name: "Обозначение - площадь" },
  { id: "total_plate", name: "Обозначение - площадь квартиры" },
  { id: "toilet", name: "Туалет" },
  { id: "bath", name: "Ванна" },
  { id: "shower", name: "Душ" },
  { id: "sink", name: "Раковина" },
  { id: "panorama", name: "Панорама" },
];

const STATUSES = [
  { id: "ready", name: "готов" },
  { id: "ready_for_render", name: "готов к рендеру" },
  { id: "render", name: "рендер" },
  { id: "render_error", name: "ошибка рендера" },
  { id: "dissolve", name: "упрощение" },
  { id: "dissolve_error", name: "ошибка упрощения" },
  { id: "awaiting_dissolve", name: "ожидает упрощения" },
  { id: "optimization", name: "оптимизация" },
  { id: "optimization_error", name: "ошибка оптимизации" },
  { id: "no_model  ", name: "модель не загружена" },
];

const OPITMIZATION_ERRORS = [
  { code: 2, error: "объекты модели не обнаружены в загруженном файле" },
  { code: 100, error: "неизвестная ошибка" },
  { code: 101, error: "неизвестная ошибка" },
  { code: 102, error: "ошибка вычисления площади полигонов" },
  { code: 103, error: "ошибка вычисления площади uv" },
  { code: 104, error: "пересечение полигонов uv" },
  { code: 105, error: "пересечение полигонов в сгенерированном uv" },
  { code: 106, error: "превышено допустимое числов uv слоев" },
  { code: 107, error: "некорректный порядок uv слоев" },
];

const VERTICAL_ALIGN = [
  { id: "top", name: "Верх" },
  { id: "bottom", name: "Низ" },
];

const extendedStatus = ({ status, optimizationError }) => {
  const st = STATUSES.find((s) => s.id === status);
  let t = (st && st.name) || status;
  if (status === "optimization_error" && optimizationError !== 0) {
    const e = OPITMIZATION_ERRORS.find((e) => e.code === optimizationError);
    t += ` (${(e && e.error) || optimizationError})`;
  }
  return t;
};

export const ItemList = (props) => (
  <List {...props} title="Категории предметов" filters={<ItemFilter />}>
    <Datagrid>
      <TextField source="zIndex" label="Z" />
      <BooleanField source="isEnabled" label="Предмет активен" />
      <BooleanField source="isConfigurationDone" label="Предмет настроен" />
      <ImageField source="imageFile.url" label="Превью" />
      <TextField source="name" label="Название" />
      <FunctionField render={extendedStatus} source="status" label="Статус" />
      <FunctionField label="Ш x В x Г" render={renderWHD} />
      <ReferenceField label="Категория" source="group" reference="itemsGroups">
        <TextField source="name" />
      </ReferenceField>
      <SelectField
        source="type"
        choices={TYPES}
        optionText="name"
        label="Тип"
      />
      <ShortUrlField source="modelFile.url" label="Модель" />
      <FunctionField render={RenderRenderUrl} label="Рендер" />
      <EditButton />
      <ShowButton />
      <RenderButton />
    </Datagrid>
  </List>
);

const validateName = [required()];
const validateType = [choices(TYPES.map((v) => v.id))];
const validateVerticalAlign = [choices(VERTICAL_ALIGN.map((v) => v.id))];
const validateGroup = [required()];
const validateWidth = [number()];
const validateHeight = [number()];
const validateDepth = [number()];
const validatePivotShift = [number()];
const validateVerticalOffset = [number()];
const validateModelFile = [required()];
const validateImageFile = [required()];
const validatePictogramFile = [required()];
const validateTag = [regex(/^[a-z0-9_]*$/, "Допустимые символы a-z, 0-9, _")];
const validateZIndex = [number()];
const validateModelObjects = [
    (value, values) => {
      if (value === null || typeof value !== "object") {
        return "Некорректная конфигурация";
      }
      if (typeof value === "object" && Object.entries(value).length === 0) {
        return "Предмет не может функционировать без добавленных объектов";
      }
      if (Array.isArray(value)) {
        return "Ожидается объект, не массив";
      }
      return undefined;
    },
];

const modelObjectsSample = `
{
    "object1": // название объекта в модели
        {
            // размер текстуры для рендера, 2 ^ n = bakeSize
            // по умолчанию размер вычисляется автоматически
            "bakeSize":  512, 

            // сэмплинг 
            "bakeSamples": 20,

            // отключение рендера для объекта
            "bakeDisabled": true,

            // вектор определяющий видимость объекта
            "viewNormal": [0, 1, 0],
            
            // флаги для дверей
            "box": true,
            "leaf": true,
            "jamb": true,
            "jamb_a": true,
            "jamb_b": true,
            

            // группа для мерджа одинаковых объектов
            "mergeGroup": "unique-group-name",
        },
    ...
}`;

class ModelObjectsHelper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showImage: false,
    };
  }

  toggleImage = (state) => {
    this.setState({ showImage: state });
  };

  render() {
    const { showImage } = this.state;
    return (
      <div>
        Формат:
        <SyntaxHighlighter language="javascript" style={docco}>
          {modelObjectsSample}
        </SyntaxHighlighter>
        <img
          src="/img/models_objects_normal.jpg"
          width={100}
          alt="вектор определяющий видимость объекта"
          onMouseEnter={() => this.toggleImage(true)}
          onMouseLeave={() => this.toggleImage(false)}
        />
        {showImage && (
          <img
            src="/img/models_objects_normal.jpg"
            alt="Пример размещения предмета"
            style={{ position: "absolute", zIndex: 1000, marginTop: -300 }}
          />
        )}
      </div>
    );
  }
}

const ItemForm = (props) => {
  const record = useRecordContext();
  return (
    <SimpleForm {...props} toolbar={<CustomToolbar save={props.save} />}>
      <BooleanInput source="isEnabled" label="Предмет активен" />
      <BooleanInput source="isConfigurationDone" label="Предмет настроен" />

      <BooleanInput
        source="isLimitedDissolveEnabled"
        label="Limited dissolve"
      />
      <BooleanInput source="isLightDissolveEnabled" label="Light dissolve" />
      <BooleanInput
        source="omitBakedUvIfPresent"
        label="Сохранять UV развертку"
      />

      <TextInput source="name" label="Название" validate={validateName} />
      <TagsAutocompleteInput
        allowEmpty={true}
        source="tag"
        label="Тэг"
        validate={validateTag}
      />

      <SelectInput
        label="Тип"
        allowEmpty={true}
        parse={(x) => x}
        source="type"
        choices={TYPES}
        validate={validateType}
      />
      <ReferenceInput
        source="group"
        label="Категория"
        reference="itemsGroups"
        valdate={validateGroup}
        sort={{ field: null, order: null }}
      >
        <SelectInput optionText={itemGroupRenderName} valdate={validateGroup} />
      </ReferenceInput>

      <NumberInput source="zIndex" label="Z индекс" validate={validateZIndex} />
      <NumberInput source="width" label="Ширина" validate={validateWidth} />
      <NumberInput source="height" label="Высота" validate={validateHeight} />
      <NumberInput source="depth" label="Глубина" validate={validateDepth} />

      <SelectInput
        label="Вертикальное выравнивание"
        source="verticalAlign"
        allowEmpty
        emptyValue=""
        choices={VERTICAL_ALIGN}
        validate={validateVerticalAlign}
      />
      <NumberInput
        source="verticalOffset"
        label="Отступ по вертикали"
        validate={validateVerticalOffset}
      />
      <NumberInput
        source="pivotShift"
        label="Отступ от точки вращения (см)"
        validate={validatePivotShift}
      />

      <TextInput
        multiline
        fullWidth={true}
        source="builtinHoleVerts"
        label="Контур проема"
        format={(v) => (v ? JSON.stringify(v) : "")}
        parse={(v) => (v ? JSON.parse(v) : "")}
      />

      <BooleanInput source="disableHoleCreation" label="Не создавать проём" />

      <BooleanInput
        source="useViewNormal"
        label="Скрывать предмет при вращении камеры"
      />

      <AjaxFileInput
        validate={validateModelFile}
        source="modelFile"
        model="App\Entity\Item"
        multiple={false}
        label="Модель (*.blend)"
      >
        <ModelDataField source="url" title="filename" meshes={true} />
      </AjaxFileInput>

      {!!record && !!record.modelOptimizedFile && (
        <Labeled label="Оптимизированная модель" fullWidth={true}>
          <ModelDataField
            source="url"
            title="filename"
            record={record.modelOptimizedFile}
            meshes={true}
          />
        </Labeled>
      )}

      <JsonInput 
        name="modelObjects"
        source="modelObjects"
        validate={validateModelObjects}
        label="Объекты модели"
      />
      <ModelObjectsHelper />

      <AjaxFileInput
        validate={validateImageFile}
        multiple={false}
        source="imageFile"
        model="App\Entity\Item"
        label="Изображение для каталога"
        fileUploader={FileUploader}
      >
        <FileField source="url" title="filename" />
      </AjaxFileInput>

      <AjaxFileInput
        validate={validatePictogramFile}
        multiple={false}
        source="pictogramFile"
        model="App\Entity\Item"
        label="Пиктограмма"
        fileUploader={FileUploader}
      >
        <SvgFileInfoField source="url" title="filename" />
      </AjaxFileInput>

      {props.children}
    </SimpleForm>
  );
};

export const ItemEdit = (props) => (
  <Edit {...props} title="Редактирование предмета"  transform={ data => ({...data, usedRenderTask: null}) }>
    <ItemForm />
  </Edit>
);

export const ItemCreate = (props) => (
  <Create {...props} title="Новый предмет">
    <ItemForm />
  </Create>
);
