import React from "react";
import { makeStyles } from "@mui/styles";
import { SaveButton, Toolbar } from "react-admin";

const useStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
});

export const CustomToolbar = ({ save, ...props }) => {
  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton save={save} />
    </Toolbar>
  );
};
