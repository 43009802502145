import jsonServerProvider from 'ra-data-json-server';
import { omit } from 'lodash';

export const RENDER = 'RENDER';

export const DataProvider = (apiUrl, httpClient) => {
    const intDataProvider = jsonServerProvider(apiUrl, httpClient);
    const customAction = (path) => (resource, params) => {
        const url = `${apiUrl}/${resource}/${params.id}/${path}`;
        const options = {
            method: 'POST',
            body: JSON.stringify(omit(params, ['id']))
        };
        return httpClient(url, options).then(response => ({data: response.json }));
    }
    intDataProvider.render = customAction('render');
    return intDataProvider;
}