import React, { useState } from 'react';
import { FileInput, useNotify } from 'react-admin';
import FileUploader from '../FileUploader';
import { fromEvent } from 'file-selector';
import { LinearProgress } from '@mui/material';

const getFilesFromEvent = (source, model, onError, beforeUpload, afterUpload) => event => {
    return fromEvent(event)
        .then(files => {
            files = files.filter(f => f instanceof File);
            beforeUpload && beforeUpload();
            return Promise.all(
                files.map(file =>
                    FileUploader(file, model, source).then(data => {
                        const transformedFile = {
                            token: data.token,
                            filename: data.name,
                            url: data.url
                        };
                        return transformedFile;
                    })
                )
            )
        })
        .then(files => files.filter(f => f !== null))
        .catch(err => { onError && onError(err); })
        .then(files => { afterUpload && afterUpload(); return files || []; })
}

const defaultOnError = notify => err => notify(err.message, 'error');

export const AjaxFileInput = props => {
    const { model, children, onError, beforeUpload, afterUpload, ...rest } = props;
    const notify = useNotify();
    const [progress, showProgress] = useState(false);
    const defaultUploadEvents = !afterUpload && !beforeUpload;
    rest.options = {
        getFilesFromEvent: getFilesFromEvent(props.source, model,
            onError || defaultOnError(notify),
            defaultUploadEvents && showProgress.bind(null, true),
            defaultUploadEvents && showProgress.bind(null, false),
            ),
    }
    return (
        <>
            <FileInput {...rest}>
                {children}
            </FileInput>
            { progress && <LinearProgress /> }
        </>
    );
}
