import {
    TextInput,
    FileField,
    Edit,
    Create,
    SimpleForm,
    List,
    TextField, 
    EditButton,
    Datagrid,
    Filter,
    required
} from 'react-admin';
import React from 'react';
import FileUploader from './FileUploader.js';
import { AjaxFileInput } from './input/';
import { ShortUrlField } from './field/';

const ResourceFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Поиск" source="_q" alwaysOn />
    </Filter>
);

export const ResourceList = props => (
    <List {...props} title="Ресурсы" filters={<ResourceFilter/>}>
        <Datagrid rowClick="edit">
            <TextField source="name" label="Название"/>
            <TextField source="type" label="Тип" />
            <ShortUrlField source="resourceFile.url"  label="Модель" />
            <EditButton />
        </Datagrid>
    </List>
);

const validateName = [required()];
const validateResourceFile = [required()];



const ResourceForm = (props) =>
    <SimpleForm {...props} >
        <TextInput source="name" label="Название" validate={validateName} />

        <AjaxFileInput validate={validateResourceFile} multiple={false} source="resourceFile" model="App\Entity\Resource" label="Файл" fileUploader={FileUploader}>
            <FileField source="url" title="filename"/>
        </AjaxFileInput>
    </SimpleForm>
    

export const ResourceCreate = props => (
    <Create {...props} title="Новый ресурс">
        <ResourceForm />
    </Create>
);

export const ResourceEdit = props => (
    <Edit {...props} title="Редактирование ресурса">
        <ResourceForm />
    </Edit>
);
