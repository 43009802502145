import { FormHelperText } from "@mui/material";
import React, { useMemo } from "react";
import { InputHelperText, Labeled, useInput } from "react-admin";
import JsonEditor from "./JsonEditor";

const JsonInput = (props) => {
  const {
    field: { value, onChange },
    fieldState: { isTouched, error },
    formState: { isSubmitted },
    isRequired,
  } = useInput(props);

  const {
    source,
    label,
    helperText
  } = props;

  const editorOnChange = (updatedContent, previousContent, { contentErrors, patchResult }) => {
    if (contentErrors || updatedContent.text === "") {
      onChange(null);
    } else {
      onChange(JSON.parse(updatedContent.text));
    }
  }

  return (
    <div style={{ width: "100%" }}>
      <Labeled
        source={source}
        isRequired={isRequired}
        fullWidth={true}
        label={label}
      >
        {useMemo(() => <JsonEditor 
          mode="text"
          mainMenuBar={false}
          onChange={editorOnChange}
          content={{text: JSON.stringify(typeof value === "object" ? value : {})}}
        ></JsonEditor>, [])}
      </Labeled>
      <FormHelperText error={(isTouched || isSubmitted) && !!error}>
        <InputHelperText
          touched={isTouched || isSubmitted}
          error={error?.message}
          helperText={helperText}
        />
      </FormHelperText>
    </div>
  );
}

export default JsonInput