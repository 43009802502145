import React from "react";
import { fetchUtils, Admin, Resource, addRefreshAuthToDataProvider, addRefreshAuthToAuthProvider} from "react-admin";
import authProvider, {refreshAuth} from "./authProvider";
import { ItemGroupList, ItemGroupEdit, ItemGroupCreate } from "./itemsGroups";
import { MaterialList, MaterialCreate, MaterialEdit } from "./materials";
import { ResourceList, ResourceCreate, ResourceEdit } from "./resources";
import { RoomTypeList, RoomTypeCreate, RoomTypeEdit } from "./roomTypes";
import { ItemList, ItemEdit, ItemCreate, ItemShow } from "./items/";
import {
  LightArrangerPresetList,
  LightArrangerPresetEdit,
  LightArrangerPresetCreate,
} from "./lightArrangerPresets";
import {
  DecorationSetList,
  DecorationSetEdit,
  DecorationSetCreate,
} from "./decorationSets";
import { RenderList, RenderShow } from "./renders";
import { CentProvider } from "./centrifugo/";
import { Grid } from "@mui/material";
import { DataProvider } from "./dataProvider";
import LoginPage from "./pages/LoginPage";
import { createBrowserHistory as createHistory } from "history";
import { API_HOST, CENTRIFUGO_URL } from "./config";
import polyglotI18nProvider from 'ra-i18n-polyglot';
import ru from 'ra-language-russian';


const history = createHistory();

const location = window.location;
const API_URL = API_HOST + "/api";
const API_AUTH_BASE = API_HOST + "/connect/eyetronic";
const API_REFRESH_TOKEN_URL = API_URL + "/token/refresh";

const generateAuthUrl = () => {
  const url = localStorage.getItem("redirectUrl");
  if (url) {
    localStorage.removeItem("redirectUrl");
  }
  return (
    API_AUTH_BASE +
    "?redirect_uri=" +
    encodeURI(location.protocol + "//" + location.host + "/auth-callback")
  );
};

const translations = { ru };

export const i18nProvider = polyglotI18nProvider(
    locale => translations[locale],
    'ru', // default locale
    [{ locale: 'ru', name: 'Русский' }],
);


const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("token");
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};
const dataProvider = DataProvider(API_URL + "/admin", httpClient);

const centrifugoToken = () => {
  return localStorage.getItem("centrifugoToken");
};

const centrifugoRef = React.createRef();

const authProviderInstance = authProvider({
  refreshTokenUrl: API_REFRESH_TOKEN_URL,
  onAuthenticated: () => {
    centrifugoRef.current.setToken(centrifugoToken());
    centrifugoRef.current.reconnect();
  },
});


export const authProviderWithRefresh = addRefreshAuthToAuthProvider(authProviderInstance, refreshAuth(API_REFRESH_TOKEN_URL));
export const dataProviderWithRefresh = addRefreshAuthToDataProvider(dataProvider, refreshAuth(API_REFRESH_TOKEN_URL));

const App = () => (
  <CentProvider
    url={CENTRIFUGO_URL}
    token={centrifugoToken()}
    ref={centrifugoRef}
  >
    <Admin
      history={history}
      loginPage={(props) => <LoginPage {...props} authUrl={generateAuthUrl} />}
      dataProvider={dataProviderWithRefresh}
      authProvider={authProviderWithRefresh}
      i18nProvider={i18nProvider}
      requireAuth={true}
    >
      {(permissions) =>
        (permissions.indexOf("ROLE_ADMIN") !== -1 && [
          <Resource
            name="itemsGroups"
            options={{ label: "Категории предметов" }}
            list={ItemGroupList}
            edit={ItemGroupEdit}
            create={ItemGroupCreate}
          />,

          <Resource
            name="materials"
            options={{ label: "Материалы" }}
            list={MaterialList}
            edit={MaterialEdit}
            create={MaterialCreate}
          />,

          <Resource
            name="items"
            options={{ label: "Предметы" }}
            list={ItemList}
            edit={ItemEdit}
            create={ItemCreate}
            show={ItemShow}
          />,

          <Resource
            name="lightArrangerPresets"
            options={{ label: "Расстановщик света" }}
            list={LightArrangerPresetList}
            edit={LightArrangerPresetEdit}
            create={LightArrangerPresetCreate}
          />,

          <Resource
            name="decorationSets"
            options={{ label: "Наборы отделки" }}
            list={DecorationSetList}
            edit={DecorationSetEdit}
            create={DecorationSetCreate}
          />,

          <Resource
            name="renders"
            options={{ label: "Рендеры" }}
            list={RenderList}
            show={RenderShow}
          />,

          <Resource
            name="roomTypes"
            options={{ label: "Типы помещений" }}
            list={RoomTypeList}
            edit={RoomTypeEdit}
            create={RoomTypeCreate}
          />,

          <Resource
            name="resources"
            options={{ label: "Ресурсы" }}
            list={ResourceList}
            edit={ResourceEdit}
            create={ResourceCreate}
          />,
        ]) || [
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: "100vh" }}
          >
            <Grid item xs={3}>
              Доступ запрещён
            </Grid>
          </Grid>,
        ]
      }
    </Admin>
  </CentProvider>
);

export default App;
