import React, { useCallback } from "react";
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  ReferenceManyField,
  Button,
  useNotify,
  useDataProvider,
  useRefresh,
  useRecordContext,
} from "react-admin";
import { RenderDataGrid } from "../renders";
import WhatshotIcon from "@mui/icons-material/Whatshot";

const SetUsedRenderButton = (props) => {
  const { item } = props;
  const itemId = item.id;

  const render = useRecordContext();
  const renderId = render.id;
    
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = useCallback(() => {
    dataProvider
      .update("items", { id: itemId, data: { usedRenderTask: renderId } })
      .then(() => {
        notify("Рендер для превью сохранен");
        refresh();
      })
      .catch((e) => notify("Ошибка сохранения: " + e, "error"));
  }, [dataProvider, itemId, notify, refresh, renderId]);

  const color =
    item.usedRenderTask && item.usedRenderTask.id === renderId
      ? "secondary"
      : "primary";

  return (
    <Button onClick={handleClick} color={color}>
      <WhatshotIcon />
    </Button>
  );
};

const ShowLayout = (props) => {
  const item = useRecordContext();
  
  return (
    <TabbedShowLayout {...props}>
      <Tab label="General">
        <TextField source="name" />
        <ReferenceManyField
          sort={{ field: "createTime", order: "DESC" }}
          reference="renders"
          target="item"
          addLabel={false}
        >
          <RenderDataGrid>
            <SetUsedRenderButton item = { item } />
          </RenderDataGrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  );
};

export const ItemShow = (props) => (
  <Show {...props}>
    <ShowLayout />
  </Show>
);

