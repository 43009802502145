import {
    NumberInput,
    TextInput,
    SelectInput,
    SelectArrayInput,
    FileField,
    Edit,
    Create,
    SimpleForm,
    List,
    TextField, 
    ImageField,
    EditButton,
    SelectField,
    Datagrid,
    Filter,
    required,
    ReferenceArrayInput,
    ReferenceArrayField,
    SingleFieldList,
    ChipField
} from 'react-admin';
import React from 'react';
import FileUploader from './FileUploader.js';
import { AjaxFileInput } from './input/';
import { ShortUrlField, ModelDataField } from './field/';

const USE_TYPES = [
    { id: 'floor', name: 'Пол'},
    { id: 'wall', name: 'Стена'},
    { id: 'ceiling', name: 'Потолок'},
    { id: 'outer_wall', name: 'Наружная стена'},
    { id: 'wall_butt', name: 'Торец стены'},
];


const MaterialFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Поиск" source="_q" alwaysOn />
    </Filter>
);

export const MaterialList = props => (
    <List {...props} title="Материалы" filters={<MaterialFilter/>}>
        <Datagrid rowClick="edit">
            <ImageField source="previewFile.url" label="Картинка в каталоге" />
            <TextField source="name" label="Название"/>
            <ShortUrlField source="modelFile.url"  label="Модель" />
            <SelectField source="useType" choices={USE_TYPES} optionText="name" label="Тип"/>
            <ReferenceArrayField label="Помещения" source="roomTypes" reference="roomTypes">
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>
            <EditButton />
        </Datagrid>
    </List>
);

const validateModelMaterial = [required()];
const validateName = [required()];
const validateUseType = [required()];
const validatePreviewFile = [required()];
const validateModelFile = [required()];
const validateTextureFile = [required()];
const validateTextureScale = [required()];

const MaterialForm = (props) => 
    <SimpleForm {...props} >
        <TextInput source="name" label="Название" validate={validateName} />
        <SelectInput label="Тип" source="useType" choices={USE_TYPES} validate={validateUseType} />

        <AjaxFileInput validate={validatePreviewFile} multiple={false} source="previewFile" model="App\Entity\Material" label="Избражения для каталога" fileUploader={FileUploader}>
            <FileField source="url" title="filename"/>
        </AjaxFileInput>

        <AjaxFileInput validate={validateModelFile} multiple={false} source="modelFile" model="App\Entity\Material" label="Модель (*.blend)" fileUploader={FileUploader}>
            <ModelDataField source="url" title="filename" materials={true}/>
        </AjaxFileInput>

        <TextInput source="modelMaterialName" label="Название материала в модели" validate={validateModelMaterial} />

        <AjaxFileInput validate={validateTextureFile} multiple={false} source="textureFile" model="App\Entity\Material" label="Текстура для предпросмотра" fileUploader={FileUploader}>
            <FileField source="url" title="filename"/>
        </AjaxFileInput>

        <NumberInput source="textureScale" label="Масштаб текстуры" validate={validateTextureScale} /> 

        <ReferenceArrayInput source="roomTypes" label="Помещения" reference="roomTypes" validate={() => {}}>
            <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
    </SimpleForm>
    


export const MaterialCreate = props => (
    <Create {...props} title="Новый материал">
        <MaterialForm />
    </Create>
);

export const MaterialEdit = props => (
    <Edit {...props} title="Редактирование материала">
        <MaterialForm />
    </Edit>
);
