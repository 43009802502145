import React, { useState, useEffect } from "react";
import { FileField, useCreate, useRecordContext } from "react-admin";
import { compose } from "recompose";
import { cent } from "../centrifugo/";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import LinearProgress from "@mui/material/LinearProgress";
import { Alert, AlertTitle } from "@mui/lab";
import { get } from "lodash";

const renderXYZ = (xyz) =>
  xyz ? (
    <span>
      x, y, z: {xyz[0]}, {xyz[1]}, {xyz[2]}
    </span>
  ) : null;

const ModelDataComponent = (props) => {
  const { source } = props;
  const url = get(useRecordContext(), source);
  const { cent } = props;
  const [modelDataId, setModelDataId] = useState();
  const [data, setData] = useState({
    loading: false,
    error: false,
    meshes: null,
    materials: null,
  });

  const [create, query] = useCreate("modelData", {
    data: { url },
  });

  useEffect(() => {
    if (url) {
      create();
      setData({ loading: true });
    }
  }, [url, create]);

  useEffect(() => {
    if (
      query.isLoading === false &&
      query.data &&
      modelDataId !== query.data.id
    ) {
      setModelDataId(query.data.id);
    }
  }, [query, modelDataId]);

  useEffect(() => {
    if (modelDataId) {
      const subscription = cent.subscribe("model_data", (message) => {
        if (message.data.request_id === modelDataId) {
          if (message.data.success) {
            const data = message.data.data;
            setData(data);
          } else {
            setData({ loading: false, error: true });
          }
        }
      });
      return () => subscription.unsubscribe();
    }
  }, [cent, modelDataId]);

  const { loading, error, materials, meshes, packed } = data || {};

  return (
    <>
      {loading ? <LinearProgress /> : null}
      {error ? <div>Не получилось загрузить данные модели</div> : null}
      {packed === false && (
        <Alert severity="error">
          <AlertTitle>Модель не запакована</AlertTitle>
        </Alert>
      )}
      {props.materials && materials && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Название</TableCell>
              <TableCell align="right">Масштаб</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {materials.map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">{renderXYZ(row.scale)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {props.meshes && meshes && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Название</TableCell>
              <TableCell align="right">Тип</TableCell>
              <TableCell align="right">Размеры</TableCell>
              <TableCell align="right">Поворот</TableCell>
              <TableCell align="right">Масштаб</TableCell>
              <TableCell align="right">Полигоны</TableCell>
              <TableCell align="right">Размер текстуры</TableCell>
              <TableCell align="right">UV overlap</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {meshes.map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  {row.name}
                  {row.modifiers ? ` (${row.modifiers})` : ""}
                </TableCell>
                <TableCell align="right">{row.type}</TableCell>
                <TableCell align="right">{renderXYZ(row.dimensions)}</TableCell>
                <TableCell align="right">{renderXYZ(row.rotation)}</TableCell>
                <TableCell align="right">{renderXYZ(row.scale)}</TableCell>
                <TableCell align="right">{row.faces}</TableCell>
                <TableCell align="right">{row.bake_size}</TableCell>
                <TableCell align="right">
                  {row.uv_overlap ? "Да" : "Нет"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
};

const ModelData = compose(cent)(ModelDataComponent);

export const ModelDataField = (props) => {
  return (
    <>
      <FileField {...props} />
      <ModelData {...props} />
    </>
  );
};
