import React from "react";
import { useEffect, useRef } from 'react'
import { JSONEditor } from "vanilla-jsoneditor";

const JsonEditor = (props) => {
  const refContainer = useRef(null);
  const refEditor = useRef(null);

  useEffect(() => {
    // create editor
    refEditor.current = new JSONEditor({
      target: refContainer.current,
      props: {}
    })

    return () => {
      // destroy editor
      if (refEditor.current) {
        refEditor.current.destroy()
        refEditor.current = null
      }
    }
  }, [])

  useEffect(() => {
    if (refEditor.current) {
      refEditor.current.updateProps(props)
    }
  }, [props])
  
  return (
    <div ref={refContainer}></div>
  );
}

export default JsonEditor