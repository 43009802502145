import React from 'react';
import {
    List,
    Datagrid,
    FunctionField,
    EditButton,
    Edit,
    Create,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    Button,
    required,
    Filter,
    useNotify, useDataProvider, useRefresh
} from 'react-admin';
import MoveUpIcon from '@mui/icons-material/ArrowUpward';
import MoveDownIcon from '@mui/icons-material/ArrowDownward';

export const renderNameWithLevel = record => {
    const arr = record.lvl > 0 ? String.fromCharCode(8594).concat(" ").repeat(record.lvl) : "";
    return `${arr}${record.name}`;
}

const ItemGroupFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Поиск" source="_q" alwaysOn />
    </Filter>
);


export const ItemGroupList = props => (
    <List {...props} title="Категории предметов" sort={{field: null, order: null}} filters={<ItemGroupFilter/>}>
        <Datagrid>
            <FunctionField label="Название" render={renderNameWithLevel} />
            <EditButton />
            <MoveButton action="up" label="Вверх" icon={<MoveUpIcon/>}  />
            <MoveButton action="down" label="Вниз" icon={<MoveDownIcon/>}  />
        </Datagrid>
    </List>
);

const validateName = [required()];
const validateParent = [required()];

const ItemGroupForm = (props) =>
    <SimpleForm {...props}>
        <TextInput source="name" label="Название" validate={validateName} />
        <ReferenceInput source="parent" label="Родительская категория" reference="itemsGroups" valdate={validateParent} sort={{field: null, order: null}}>
            <SelectInput optionText={renderNameWithLevel} valdate={validateParent} />
        </ReferenceInput>
    </SimpleForm>

export const ItemGroupEdit = props => (
    <Edit {...props} title="Редактирование категории">
        <ItemGroupForm />
    </Edit>
);

export const ItemGroupCreate = props => (
    <Create {...props}  title="Новая категория">
        <ItemGroupForm />
    </Create>
);

const MoveButton = ({ record = {}, icon, action, label }) => {
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();

    const click = () => {
        dataProvider.update("itemsGroups", { id: record.id, data: { action: action } })
            .then(() => {
                notify("Категория перемещена " + label);
                refresh();
            })
            .catch(e => notify(e))
        return false;
    }

    return <Button label={label} onClick={click}>{icon}</Button>
}
