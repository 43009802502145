import { API_HOST } from "./config";

const fileUploader = (endpoint) => (file, model, field) => {
    const url = endpoint + "?model="  + encodeURIComponent(model) + 
        "&field=" + encodeURIComponent(field) + 
        "&filename=" + encodeURIComponent(file.name);

    return fetch(url, {
        method: "POST",
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("token"),
            "Content-Type": file.type
        },
        body: file
    }).then(response => {
        return response.json()
    }).then(data => {
        if("code" in data) {
            throw new Error("message" in data ? data.message : "Bad response from server");
        }
        return data;
    });
}

export default fileUploader(API_HOST + "/api/upload");