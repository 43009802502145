import {
  ArrayInput,
  TextInput,
  SelectInput,
  Edit,
  Create,
  SimpleForm,
  List,
  TextField,
  ReferenceInput,
  EditButton,
  SimpleFormIterator,
  Datagrid,
  Filter,
  required,
  FormDataConsumer,
  LinearProgress,
  Error,
  useGetMany,
} from "react-admin";
import React from "react";
import { uniqBy } from "lodash";
import { TagsAutocompleteInput } from "./input/";

const DecorationSetFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Поиск" source="_q" alwaysOn />
  </Filter>
);

export const DecorationSetList = (props) => (
  <List {...props} title="Наборы отделки" filters={<DecorationSetFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="name" label="Название" />
      <EditButton />
    </Datagrid>
  </List>
);

const validateUniqueTaggedItems = (taggedItems) => {
  if (taggedItems && taggedItems.length !== uniqBy(taggedItems, "tag").length) {
    return "Тэги не должны повторяться";
  }
  return [];
};

const validateRequired = [required()];
const validateTaggedItems = [validateUniqueTaggedItems];

const DecorationSetForm = (props) => (
  <SimpleForm {...props}>
    <TextInput source="name" label="Название" />

    <ReferenceInput
      source="wcWallMaterial"
      label="Материал стен санузла"
      reference="materials"
      filter={{ _q: "wall" }}
      validate={validateRequired}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>

    <ReferenceInput
      source="wcFloorMaterial"
      label="Материал полов санузла"
      reference="materials"
      filter={{ _q: "floor" }}
      validate={validateRequired}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>

    <ReferenceInput
      source="roomWallMaterial"
      label="Материал стен комнаты"
      reference="materials"
      filter={{ _q: "wall" }}
      validate={validateRequired}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>

    <ReferenceInput
      source="roomFloorMaterial"
      label="Материал полов комнаты"
      reference="materials"
      filter={{ _q: "floor" }}
      validate={validateRequired}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>

    <ReferenceInput
      source="balconyWallMaterial"
      label="Материал стен балкона, сауны"
      reference="materials"
      filter={{ _q: "wall" }}
      validate={validateRequired}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>

    <ReferenceInput
      source="balconyFloorMaterial"
      label="Материал полов балкона, сауны"
      reference="materials"
      filter={{ _q: "floor" }}
      validate={validateRequired}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>

    <ReferenceInput
      source="toiletItem"
      label="Модель туалета"
      reference="items"
      filter={{ _q: "toilet" }}
      validate={validateRequired}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>

    <ReferenceInput
      source="bathItem"
      label="Модель ванны"
      reference="items"
      filter={{ _q: "bath" }}
      validate={validateRequired}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>

    <ReferenceInput
      source="showerItem"
      label="Модель душа"
      reference="items"
      filter={{ _q: "shower" }}
      validate={validateRequired}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>

    <ReferenceInput
      source="sinkItem"
      label="Модель раковины"
      reference="items"
      filter={{ _q: "sink" }}
      validate={validateRequired}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>

    <ArrayInput
      source="taggedItems"
      label="Замена по тэгам"
      validate={validateTaggedItems}
    >
      <SimpleFormIterator>
        <TagsAutocompleteInput
          label="Тэг"
          source="tag"
          validate={validateRequired}
        />
        <FormDataConsumer subscription={{ values: true }} label="Предмет">
          {({ formData, scopedFormData, getSource, ...rest }) => {
            return (
              scopedFormData &&
              scopedFormData.tag && (
                <TagItemsSelectInput
                  key={getSource("item")}
                  tag={scopedFormData.tag}
                  source={getSource("item")}
                  {...rest}
                  validate={validateRequired}
                />
              )
            );
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  </SimpleForm>
);

const TagItemsSelectInput = (props) => {
  const { loaded, error, data } = useGetMany("items", {
    filter: { _q: props.tag },
    pagination: { page: 1, perPage: 999 },
    sort: { field: "name", order: "ASC" },
  });

  return error ? (
    <Error />
  ) : loaded ? (
    <SelectInput
      {...props}
      choices={data.map(({ id, name }) => ({ id, name }))}
    />
  ) : (
    <LinearProgress />
  );
};

export const DecorationSetCreate = (props) => (
  <Create {...props} title="Новый набор отделки">
    <DecorationSetForm />
  </Create>
);

export const DecorationSetEdit = (props) => (
  <Edit {...props} title="Редактировать набор отделки">
    <DecorationSetForm />
  </Edit>
);
