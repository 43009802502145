const API_HOST = 
    process.env.REACT_APP_API_HOST || window._env_?.API_HOST;
const CENTRIFUGO_URL = 
    process.env.REACT_APP_CENTRIFUGO_URL || window._env_?.CENTRIFUGO_URL;
const PLAYER_URL_TEMPLATE = 
    process.env.REACT_APP_PLAYER_URL_TEMPLATE || window._env_?.PLAYER_URL_TEMPLATE;
const FLOOR_PLAN_URL_TEMPLATE = 
    process.env.REACT_APP_FLOOR_PLAN_URL_TEMPLATE || window._env_?.FLOOR_PLAN_URL_TEMPLATE;

export {
    API_HOST,
    CENTRIFUGO_URL,
    PLAYER_URL_TEMPLATE,
    FLOOR_PLAN_URL_TEMPLATE
}