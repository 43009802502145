import {
    DateField, Datagrid, FunctionField,
    List, NumberField, SelectField, ShowButton,
    Show, TabbedShowLayout, Tab, TextField, useDataProvider, useNotify, useRefresh,
    ChipField, useRecordContext
} from 'react-admin';
import React, {useState, useEffect, useCallback} from 'react';
import Launch from '@mui/icons-material/Launch';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {docco} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import formatJson from 'format-json';
import {CommentButton} from './button/CommentButton';
import {BytesField} from './field/BytesField';
import Rating from '@mui/lab/Rating';
import { FLOOR_PLAN_URL_TEMPLATE, PLAYER_URL_TEMPLATE } from './config';

const STATUSES = [
    {id: 'model_grow', label: 'формирование модели'},
    {id: 'grower_error', label: 'ошибка формирования модели'},
    {id: 'model_upload ', label: 'загрузка модели'},
    {id: 'render_queued', label: 'рендер'},
    {id: 'render_upload', label: 'загрузка рендера'},
    {id: 'render_error', label: 'ошибка рендера'},
    {id: 'postprocess', label: 'постобработка'},
    {id: 'screenshot_queued', label: 'формируется скриншот'},
    {id: 'screenshot_error', label: 'ошибка скриншота'},
    {id: 'postprocess_upload', label: 'ошибка постобработки'},
    {id: 'publisher_error', label: 'ошибка постобработки'},
    {id: 'done', label: 'готов'},
    {id: 'error', label: 'ошибка'}
];

const FloorPlanLink = id =>
    id ? <a href={FLOOR_PLAN_URL_TEMPLATE.replace("%id", id)}>{id}</a> : "-";

const RenderRenderUrl = record => record.status === 'done' ?
    <a href={PLAYER_URL_TEMPLATE.replace("%id", record.id)}><Launch/></a> : null;

const RenderCommentButton = () => {
    const {comment, id} = useRecordContext();
    const dataProvider = useDataProvider();
    const [values, setValues] = useState(null);
    const notify = useNotify();
    const refresh = useRefresh();

    useEffect(() => {
        if (values) {
            const {comment} = values;
            dataProvider
                .update("renders", {id, data: {comment}})
                .then(() => {
                    notify("Комментарий сохранен");
                    refresh();
                })
                .catch(e => notify("Ошибка сохранения комментария: " + e, 'error'));
        }
    }, [id, dataProvider, notify, refresh, values])

    return <CommentButton initialValues={{comment}} onSubmit={values => setValues(values)}/>
}

const renderParams = ({id, comment, bakeSamples, bakeTypes, bakeSizes}) => (
    <React.Fragment>
        {[bakeSizes, bakeTypes, bakeSamples, comment]
            .filter(v => Array.isArray(v) ? v.length !== 0 : v)
            .map(v => Array.isArray(v) ? v.join(" / ") : v)
            .map((v, k) => <div key={k}>{v}</div>)}
    </React.Fragment>
)

const RenderQualityButton = () => {
    const {status, id, quality} = useRecordContext();

    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const notify = useNotify();


    const setValue = useCallback((event, value) => {
        dataProvider
            .update("renders", {id, data: {quality: value}})
            .then(() => {
                notify("Оценка сохранена");
                refresh();
            })
            .catch(e => notify("Ошибка сохранения: " + e, 'error'));
    }, [dataProvider, id, notify, refresh]);

    return <Rating
        name={"quality" + id}
        value={quality ? quality : 0}
        readOnly={status !== "done"}
        onChange={setValue}
    />
}


export const RenderDataGrid = props => (
    <Datagrid {...props}>
        <FunctionField render={renderParams} source="id" label="Параметры"/>
        <SelectField source="status" choices={STATUSES} optionText="label" label="Статус"/>
        <DateField source="createTime" showTime label="Создан"/>
        <DateField source="updateTime" showTime label="Обновлен"/>
        <DateField source="finishTime" showTime label="Завершен"/>
        <NumberField source="priceGPU" label="GPU"/>
        <NumberField source="priceCPU" label="CPU"/>
        <BytesField source="modelSize" label="Размер модели"/>
        <BytesField source="texturesSize" label="Размер текстур"/>
        <RenderQualityButton/>
        <FunctionField render={RenderRenderUrl} label=""/>
        <ShowButton label=""/>
        <RenderCommentButton/>
        {props.children}
    </Datagrid>
)

export const RenderList = props => (
    <List {...props} sort={{field: 'createTime', order: 'DESC'}}>
        <RenderDataGrid/>
    </List>
)

const JsonField = ({source, record = {}}) => (
    <SyntaxHighlighter language="javascript" style={docco}>
        {formatJson.plain(record[source] || null)}
    </SyntaxHighlighter>
)

const RenderId = (source, record = {}) => {
    const v = record[source];
    return v ? `${v} (0x${v.replace(/-/g, '')})` : '-';
}


export const RenderShow = (props) => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label="General">
                <FunctionField label="id" render={record => RenderId("id", record)}/>
                <TextField source="targetType" label="Цель"/>
                <FunctionField label="Планировка" render={record => FloorPlanLink(record.layoutFloorPlan)}/>
                <FunctionField label="GrowerTask" render={record => RenderId("growerTask", record)}/>
                <SelectField source="status" choices={STATUSES} optionText="label" label="Статус"/>
                <TextField source="customRenderS3Src" label="Custom render source"/>
                <DateField source="createTime" showTime label="Создан"/>
                <DateField source="updateTime" showTime label="Обновлен"/>
                <DateField source="finishTime" showTime label="Завершен"/>
                <NumberField source="priceGPU" label="GPU"/>
                <NumberField source="renderTimeGPU" label="Время GPU"/>
                <NumberField source="priceCPU" label="CPU"/>
                <NumberField source="renderTimeCPU" label="Время CPU"/>

                <ChipField source="bakeSizes" label="Размер рендера"/>
                <ChipField source="bakeTypes" label="Тип рендера"/>
                <ChipField source="bakeSamples" label="Сэмплинг"/>
                <TextField source="comment" label="Комментарий"/>
            </Tab>
            <Tab label="Jobs">
                <JsonField source="renderJobs"/>
            </Tab>
            <Tab label="Time explained">
                <JsonField source="renderTimePerJob"/>
            </Tab>
        </TabbedShowLayout>
    </Show>
);
