import {
    TextInput,
    SelectInput,
    Edit,
    Create,
    SimpleForm,
    List,
    TextField,
    BooleanInput,
    EditButton,
    SelectField,
    BooleanField,
    Datagrid,
    Filter,
    required
} from 'react-admin';
import React from 'react';

const TYPE_GROUP = [
    { id: 'room', name: 'Комната' },
    { id: 'bedroom', name: 'Спальня' },
    { id: 'living_room', name: 'Гостиная' },
    { id: 'cabinet', name: 'Кабинет' },
    { id: 'kitchen', name: 'Кухня' },
    { id: 'hall', name: 'Коридор' },
    { id: 'utility', name: 'Тех. помещение' },
    { id: 'balcony', name: 'Балкон' },
    { id: 'wic', name: 'Гардеробная' },
    { id: 'sauna', name: 'Сауна' },
    { id: 'wc', name: 'Санузел' },
];


const RoomTypeFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Поиск" source="_q" alwaysOn />
    </Filter>
);

export const RoomTypeList = props => (
    <List {...props} title="Типы помещений" filters={<RoomTypeFilter/>}>
        <Datagrid rowClick="edit">
            <TextField source="name" label="Название"/>
            <SelectField source="group" choices={TYPE_GROUP} optionText="name" label="Группа" />
            <BooleanField source="isRequired" label="Обязательное" />
            <EditButton />
        </Datagrid>
    </List>
);

const validateName = [required()];
const validateGroup = [required()];

const RoomTypeForm = (props) =>
    <SimpleForm {...props} >
        <TextInput source="name" label="Название" validate={validateName} />
        <SelectInput label="Группа" source="group" choices={TYPE_GROUP} validate={validateGroup} />
        <BooleanInput source="isRequired" label="Обязательное" />
    </SimpleForm>

export const RoomTypeCreate = props => (
    <Create {...props} title="Новый тип помещения">
        <RoomTypeForm />
    </Create>
);

export const RoomTypeEdit = props => (
    <Edit {...props} title="Редактирование типа помещения">
        <RoomTypeForm />
    </Edit>
);
