import React, { useState } from "react";
import {
  Button,
  Confirm,
  useNotify,
  useDataProvider,
  useRefresh,
  useRecordContext,
} from "react-admin";
import FlashOn from "@mui/icons-material/FlashOn";
import { Tooltip } from "@mui/material";

const statuses = {
  null: "disabled",
  model_grow: "secondary",
  model_upload: "secondary",
  render_queued: "secondary",
  render_upload: "secondary",
  render_error: "error",
  postprocess: "secondary",
  postprocess_upload: "secondary",
  publisher_error: "error",
  done: "primary",
  error: "error",
  grower_error: "error",
};

const RenderButton = (props) => {
  const record = useRecordContext();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const [open, setOpen] = useState(false);

  const handleRender = () => {
    setOpen(false);
    dataProvider
      .render("items", { id: record.id })
      .then(() => {
        notify("Render queued");
        refresh();
      })
      .catch((e) => {
        notify("Item render error\n" + e, "warning");
      });
  };
  const handleDialogClose = () => setOpen(false);
  const confirm = () => setOpen(true);

  const { currentRenderTask } = record;
  const error =
    currentRenderTask &&
    currentRenderTask.status &&
    currentRenderTask.status.indexOf("error") !== -1;
  const notDone =
    currentRenderTask && !error && currentRenderTask.status !== "done";
  const status = currentRenderTask && currentRenderTask.status;

  return (
    <Tooltip title={currentRenderTask ? currentRenderTask.status : ""}>
      <div>
        <Button onClick={confirm} label="Рендер">
          <FlashOn color={statuses[status]} />
        </Button>
        {open && (
          <Confirm
            isOpen={open}
            title="Рендер"
            content={
              "Вы уверены?" +
              (notDone ? " Предыдущий рендер ещё не завершен." : "")
            }
            onConfirm={handleRender}
            onClose={handleDialogClose}
          />
        )}
      </div>
    </Tooltip>
  );
};

export default RenderButton;
