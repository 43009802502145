import React, {useState, useCallback} from 'react';
import Dialog from "@mui/material/Dialog";
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from '@mui/material/TextField';
import {Button as ActionButton} from 'react-admin';
import {useForm, Controller} from 'react-hook-form';
import {Button} from '@mui/material';
import CommentIcon from '@mui/icons-material/Comment';


export const CommentButton = ({onSubmit, initialValues}) => {
    const [dialogVisible, showDialog] = useState(false);
    const doSubmit = useCallback((values) => {
        onSubmit(values);
        showDialog(false);
    }, [onSubmit])
    const onClose = useCallback(() => showDialog(false), []);
    const {submitting, handleSubmit, control} = useForm({
        defaultValues: initialValues
    });

    return (
        <React.Fragment>
            <ActionButton onClick={() => showDialog(true)}><CommentIcon/></ActionButton>
            <Dialog open={dialogVisible} onClose={onClose} fullWidth>
                <form onSubmit={handleSubmit(doSubmit)}>
                    <DialogTitle>Комментарий</DialogTitle>
                    <DialogContent>
                        <Controller
                            name="comment"
                            control={control}
                            render={({field: {onChange, value}}) => (
                                <TextField onChange={onChange} value={value} fullWidth={true}/>
                            )}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" disabled={submitting} color="primary">
                            Сохранить
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </React.Fragment>
    )
}
