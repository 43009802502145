import {
    NumberInput,
    TextInput,
    SelectInput,
    Edit,
    Create,
    SimpleForm,
    List,
    TextField,
    ReferenceField,
    ReferenceInput,
    EditButton,
    Datagrid,
    Filter,
    required
} from 'react-admin';
import React from 'react';

const LightArrangerPresetFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Поиск" source="_q" alwaysOn />
    </Filter>
);

export const LightArrangerPresetList = props => (
    <List {...props} title="Пресеты расстановщика света" filters={<LightArrangerPresetFilter />}>
        <Datagrid rowClick="edit">
            <ReferenceField label="Материал стен" source="roomWallMaterial" reference="materials">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="areaFrom" label="Площадь от" />
            <TextField source="areaTo" label="Площадь до" />
            <TextField source="dist" label="Дистанция" />
            <ReferenceField label="Источник света" source="item" reference="items">
                <TextField source="name" />
            </ReferenceField>
            <EditButton />
        </Datagrid>
    </List>
);

const validateRoomWallMaterial = [required()];
const validateAreaFrom = [required()];
const validateAreaTo = [required()];
const validateDist = [required()];
const validateItem = [required()];

const LightArrangerPresetForm = (props) =>
    <SimpleForm {...props} >
        <ReferenceInput source="roomWallMaterial" label="Материал стен" reference="materials" filter={{ _q: "wall" }} validate={validateRoomWallMaterial}>
            <SelectInput optionText="name" />
        </ReferenceInput>
        <NumberInput source="areaFrom" label="Площадь от (кв. м.)" validate={validateAreaFrom}/>
        <NumberInput source="areaTo" label="Площадь до (кв. м.)" validate={validateAreaTo}/>
        <NumberInput source="dist" label="Дистанция" helperText="Дистанция между источниками освещения" validate={validateDist}/>

        <ReferenceInput source="item" label="Источник освещения" reference="items" filter={{ _q: "light" }} validate={validateItem}>
            <SelectInput optionText="name" />
        </ReferenceInput>
    </SimpleForm>


export const LightArrangerPresetCreate = props => (
    <Create {...props} title="Новый пресет">
        <LightArrangerPresetForm />
    </Create>
);

export const LightArrangerPresetEdit = props => (
    <Edit {...props} title="Редактировать пресет">
        <LightArrangerPresetForm />
    </Edit>
);
