import React from "react";
import {
  AutocompleteInput,
  Error,
  LinearProgress,
  useInput,
  useGetMany,
} from "react-admin";
import Autocomplete from "@mui/lab/Autocomplete";
import MuiTextField from "@mui/material/TextField";

const CustomAutocomplete = ({ label, options, className, ...props }) => {
  const {
    field,
    fieldState: { isTouched, invalid, error },
    formState: { isSubmitted },
  } = useInput(props);

  return (
    <Autocomplete
      options={options}
      renderInput={({ fullWidth, ...params }) => (
        <MuiTextField
          {...params}
          {...field}
          label={label}
          className={className}
          error={(isTouched || isSubmitted) && invalid}
          helperText={(isTouched || isSubmitted) && invalid ? error : ""}
        />
      )}
    />
  );
};

export const TagsAutocompleteInput = ({ allowEmpty = false, ...props }) => {
  const { isLoading, error, data } = useGetMany("itemTags", {
    pagination: { page: 1, perPage: 999 },
    sort: { field: "tag", order: "ASC" },
  });

  if (isLoading) {
    return <LinearProgress />;
  }
  if (error) {
    return <Error error={error} />;
  }

  return allowEmpty ? (
    <CustomAutocomplete
      label="Тэг"
      {...props}
      freeSolo={allowEmpty}
      options={data.map(({ tag }) => tag)}
    />
  ) : (
    <AutocompleteInput
      {...props}
      choices={data.map(({ tag }) => ({ id: tag, name: tag }))}
    />
  );
};
